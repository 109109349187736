import React, { useEffect, useState } from "react";
import WhatWeDoBanner from "../components/WhatWeDoBanner";
import WhatWeDoInfo from "../components/WhatWeDoInfo";
import WhatWeDoAccordin from "../components/WhatWeDoAccordin";
import Contact from "../components/Contact";
import WhatWeDOPicture from "../components/WhatWeDOPicture";
import axios from "axios";

const WhatWeDo = () => {
  const [data, setData] = useState([]);
  
  const getTalent = async () => {
    try {
      const response = await axios.get(
        "https://premiumidapi.dev-sh.xyz/api/what-we-do"
      );
      const newData = response.data.data;
      console.log(newData,'newData');
      setData(newData || []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTalent();
  }, []);
  return (
    <>
      <WhatWeDoBanner databanner={data?.banner_video}/>
      <WhatWeDoInfo />
      <WhatWeDoAccordin />
      <WhatWeDOPicture data={data}/>
      <Contact />
    </>
  );
};

export default WhatWeDo;
