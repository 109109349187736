import React, { useEffect, useRef, useState } from "react";
import {
  motion,
  useAnimation,
  useInView,
  useScroll,
  useTransform,
} from "framer-motion";
import person1bottemlleft from "../assets/img/talents/1-3.png";
import person1bottemright from "../assets/img/talents/1-4.png";
import person1topright from "../assets/img/talents/1-2.png";
import person1Topleft from "../assets/img/talents/1-1.png";
import Left from "../assets/img/talents/01.png";
import Middle from "../assets/img/talents/02.png";
import Right from "../assets/img/talents/03.png";
import person2bottemlleft from "../assets/img/talents/2-3.png";
import person2bottemright from "../assets/img/talents/2-4.png";
import person2topright from "../assets/img/talents/2-2.png";
import person2Topleft from "../assets/img/talents/2-1.png";
import person3bottemlleft from "../assets/img/talents/3-3.png";
import person3bottemright from "../assets/img/talents/3-4.png";
import person3topright from "../assets/img/talents/3-2.png";
import person3Topleft from "../assets/img/talents/3-1.png";
import { useTheme } from "../Context/useTheme";
import { useNavigate } from "react-router-dom";
import HeadingAnimation from "./HeadingAnimation";
import axios from "axios";

const OurTalents = () => {
  const [data, setData] = useState([]);
  const getTalent = async () => {
    try {
      const response = await axios.get(
        "https://premiumidapi.dev-sh.xyz/api/home"
      );
      setData(response?.data?.data);
      console.log(response?.data?.data, "presss-->>>>");
    } catch (error) {
      console.log(error, "error-->>>>");
    }
  };

  useEffect(() => {
    getTalent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const targetRef = useRef(null);
  const controls = useAnimation();
  const sectionViewCheck = useInView(targetRef);
  const { toggleTheme, toggleisHome } = useTheme();
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end end"],
  });

  useTransform(scrollYProgress, (pos) => {
    if (pos > 0 && pos < 1) {
      toggleTheme("dark");
    }
  });

  useEffect(() => {
    if (sectionViewCheck) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, sectionViewCheck]);

  useEffect(() => {
    if (sectionViewCheck) {
      toggleisHome(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionViewCheck]);

  const navigate = useNavigate();

  return (
    <>
      <motion.div
        className="container-fluid talent-section custom-padding"
        ref={targetRef}
        animate={controls}
        initial={{ backgroundColor: "#202020" }}
        variants={{
          visible: { backgroundColor: "#F1F2F2" },
          hidden: { backgroundColor: "#202020" },
        }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
          delay: 1,
          duration: 0.3,
        }}
      >
        <div className="row talent-images ">
          {data.map((talent) => (
            <div className="col-12 col-md-4 col-lg-4 g-0" key={talent.id}>
              <div className="row g-0  talent-images">
                <motion.div
                  initial={{ filter: "grayscale(1)" }}
                  whileInView={{ filter: "grayscale(0)" }}
                  viewport={{ once: true }}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                    delay: 0.5,
                    duration: 0.3,
                  }}
                >
                  <img
                    src={talent.image}
                    alt=""
                    className="img-fluid w-100"
                    style={{ borderRadius: "4%" }}
                  />
                </motion.div>

                <div className="talent-name text-white">{talent.name}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="row mt-2">
          <div className="col-md-12 col-12 col-lg-12">
            <HeadingAnimation
              text={"OUR TALENTS"}
              elementType={"h1"}
              classes="text-antonio text-dark inline-thirteen"
            >
              <span className="fs-4 mx-4 inline-fifteen">01</span>
            </HeadingAnimation>
            <motion.hr
              className="inline-sixteen d-none  d-lg-block"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
                delay: 0.3,
                duration: 0.5,
              }}
            />
            <motion.hr
              className="inline-sixteen d-block  d-lg-none"
              initial={{ opacity: 0, width: "0%" }}
              whileInView={{ opacity: 1, width: "100%" }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
                delay: 0.3,
                duration: 0.5,
              }}
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-4 col-12 col-lg-4">
            <p className="talent-text">
              We manage talented creators who have a{" "}
              <span className="text-primary fw-bolder inline-seventeen">
                very distinctive way to create content
              </span>{" "}
              and establish a real connection with their audience.
            </p>
          </div>
          <div className="col-md-4 col-12 col-lg-4">
            <p className="talent-text">
              Our focus is to deal with fashion and beauty topics, aiming to{" "}
              <span className="fw-bolder">elevate influencer marketing</span> to
              a new exclusive level.
            </p>
          </div>
        </div>
        <div className="row my-0 my-md-5">
          <div
            className="col-md-12 text-center 
          "
          >
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
                delay: 0.4,
                duration: 0.2,
              }}
              className="stroked-wrapper "
            >
              <div
                onClick={() => navigate("talent")}
                className="stroked mt-4 "
                data-content="DISCOVER"
              >
                DISCOVER
              </div>
            </motion.div>
            {/* <h1 className=" display-2"></h1> */}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default OurTalents;
