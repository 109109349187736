import React, { useEffect, useRef, useState } from "react";
import Marquee from "react-fast-marquee";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { A11y, Autoplay, FreeMode } from "swiper/modules";
import { useTheme } from "../../Context/useTheme";
import { useInView } from "framer-motion";
import axios from "axios";
import Image1 from "../../assets/img/talentslider/1-1.png";
import Image12 from "../../assets/img/talentslider/1-2.png";
import Image14 from "../../assets/img/talentslider/1-4.jpg"; 
import Image15 from "../../assets/img/talentslider/1-4.jpg"; 
import Image13 from "../../assets/img/talentslider/1-4.jpg"; 
import Image23 from "../../assets/img/talentslider/1-5.jpg";
import Image16 from "../../assets/img/talentslider/1-6.png";

import Image2 from "../../assets/img/talentslider/2-1.png";
import Image22 from "../../assets/img/talentslider/2-2.png";
import Image24 from "../../assets/img/talentslider/2-4.jpg";
import Image25 from "../../assets/img/talentslider/2-5.jpg";
import Image26 from "../../assets/img/talentslider/2-6.png";

import Image3 from "../../assets/img/talentslider/3-1.png";
import Image32 from "../../assets/img/talentslider/3-2.png";
import Image33 from "../../assets/img/talentslider/3-3.png";
import Image34 from "../../assets/img/talentslider/3-4.jpg";
import Image35 from "../../assets/img/talentslider/3-5.jpg";
import { useMediaQuery } from "../../hooks/useMediaQuery";

const TalentSliderMobile = () => {
  const slider1 = [
    {
      img: Image1,
      name: "Benedetta Bottin",
    },

    {
      img: Image12,
      name: "Alessandra Belda",
    },
    {
      img: Image13,
      name: "Elena Petrin",
    },
    {
      img: Image14,
      name: "Arianna Rinaldi",
    },
    {
      img: Image15,
      name: "Alice Poletti",
    },
  ];
  const slider2 = [
    {
      img: Image2,
      name: "Benedetta Bottin",
    },

    {
      img: Image22,
      name: "Ludovica Nicolò",
    },
    {
      img: Image23,
      name: "Giulia Cappelli",
    },
    {
      img: Image24,
      name: "Senada",
    },
    {
      img: Image25,
      name: "Alessandra Belda",
    },
  ];
  const slider3 = [
    {
      img: Image3,
      name: "Martina Cau",
    },

    {
      img: Image32,
      name: "Marta Vincenzi",
    },
    {
      img: Image33,
      name: "Serena e Letizia Reginelli",
    },
    {
      img: Image34,
      name: "Greta Filippeschi",
    },
    {
      img: Image35,
      name: "Gabriel Nobile",
    },
  ];
  const swipper1 = useRef(null);
  const swipper2 = useRef(null);
  const swipper3 = useRef(null);

  const targetRef = useRef(null);
  const { toggleTheme, toggleisHome } = useTheme();
  const mobileView = useMediaQuery("(min-width: 480px)");
  const sectionViewCheck = useInView(targetRef);
  const swipper1ViewCheck = useInView(swipper1);
  const [data, setData] = useState();
  console.log(swipper1ViewCheck);
  useEffect(() => {
    if (sectionViewCheck) {
      toggleTheme("light");
      toggleisHome(false);
    }
  }, [sectionViewCheck]);
  useEffect(() => {
    const handleScroll = () => {
      if (swipper1.current && swipper1ViewCheck) {
        // console.log(swipper1.current.swiper)
        // swipper1.current.swiper.params.autoplay.delay = 0
        // swipper1.current.swiper.autoplay.start()
        const currentSlide = swipper1.current.swiper.activeIndex;
        swipper1.current.swiper.slideTo(currentSlide + 1);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [swipper1ViewCheck]);
  const getTalent = async () => {
    try {
      const response = await axios.get(
        "https://premiumidapi.dev-sh.xyz/api/talent-user"
      );
      setData(response?.data?.data);
      console.log(response?.data?.data, "presss-->>>>");
    } catch (error) {
      console.log(error, "error-->>>>");
    }
  };

  useEffect(() => {
    getTalent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        ref={targetRef}
        className="bg-dark  overflow-hidden talent-slider-section g-0 m-0 p-0 d-none "
      >
        <div className="talent-marquee">
          <Marquee speed={200}>
            <span className="text-white me-3">OUR {"      "}</span>

            <span className="text-secondary me-3">PREMIUM {"      "}</span>
            <span className="text-primary me-3">CREATORS {"      "}</span>
          </Marquee>
        </div>
        {data?.map((rowData, index) => (
        <div
          className="row g-0 overflow-hidden "
          key={rowData.row}
          style={{
            // y,
            position: "relative",
            top: "-7vw",
            zIndex: 1,
          }}
        >
          {rowData.talent.map((talent) => (
          <div className="col-12 col-lg-12 col-md-12 talent-slider px-0 px-md-2 mobile-talent-slider inline-twentyone">
            <Swiper
              ref={swipper1}
              slidesPerView={"auto"}
              mousewheel={{ eventsTarget: ".inline-twentyone", sensitivity: 5 }}
              keyboard={{ enabled: true, onlyInViewport: true }}
              spaceBetween={mobileView ? 50 : 20}
              speed={1000}
              freeMode={true}
              // loop={true}
              modules={[A11y, FreeMode, Autoplay]}
              onSlideChange={(e) => {
                if (e.isEnd) {
                  swipper1.current.swiper.autoplay.stop();
                  swipper2.current.swiper.autoplay.start();
                  swipper2.current.swiper.params.autoplay.delay = 10;
                }
              }}
            >
              {/* {talent.map((e) => { */}
                {/* return ( */}
                  <SwiperSlide className="inline-twentytwo">
                    <div className="item w-100">
                      <img
                        src={talent?.image}
                        className="img-fluid rounded-3 w-100"
                        alt=""
                      />
                      <div className="content position-absolute bottom-0 start-0 ps-3">
                        <p className="text-white text-uppercase   font-telegraf">
                          {talent?.name}
                        </p>
                        <p className="text-white fw-normal font-telegraf">
                          {talent?.insta}
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                {/* ); */}
              {/* })} */}
            </Swiper>
          </div>
            ))}
          {/* <div className="col-12 col-lg-12 col-md-12 talent-slider  px-0 px-md-2 mt-4 mobile-talent-slider inline-twentyone">
            <Swiper
              ref={swipper2}
              slidesPerView={3.5}
              spaceBetween={mobileView ? 50 : 20}
              // autoplay={{
              //   stopOnLastSlide:true
              // }}
              speed={1000}
              freeMode={true}
              // loop={true}
              modules={[A11y, FreeMode, Autoplay]}
              onSlideChange={(e) => {
                if (e.isEnd) {
                  swipper2.current.swiper.autoplay.stop();

                  swipper3.current.swiper.autoplay.start();
                  swipper3.current.swiper.params.autoplay.delay = 10;
                }
              }}
            >
              {slider2.map((e) => {
                return (
                  <SwiperSlide className="inline-twentytwo">
                    <div className="item w-100">
                      <img
                        src={e.img}
                        className="img-fluid rounded-3 w-100"
                        alt=""
                      />
                      <div className="content position-absolute bottom-0 start-0 ps-3">
                        <p className="text-white text-uppercase   font-telegraf">
                          {e.name}
                        </p>
                        <p className="text-white fw-normal font-telegraf">
                          {e.name}
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="col-12 col-lg-12 col-md-12 talent-slider  px-0 px-md-2 mt-4 mobile-talent-slider inline-twentyone">
            <Swiper
              ref={swipper3}
              slidesPerView={3.5}
              spaceBetween={mobileView ? 50 : 20}
              // autoplay={{
              //   stopOnLastSlide:true
              // }}
              speed={1000}
              // loop={true}
              freeMode={true}
              modules={[A11y, FreeMode, Autoplay]}
              onSlideChange={(e) => {
                if (e.isEnd) {
                  swipper3.current.swiper.autoplay.stop();
                }
              }}
            >
              {slider3.map((e) => {
                return (
                  <SwiperSlide className="inline-twentytwo">
                    <div className="item w-100">
                      <img
                        src={e.img}
                        className="img-fluid rounded-3 w-100"
                        alt=""
                      />
                      <div className="content position-absolute bottom-0 start-0 ps-3">
                        <p className="text-white text-uppercase   font-telegraf">
                          {e.name}
                        </p>
                        <p className="text-white fw-normal font-telegraf">
                          {e.name}
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div> */}
        </div>
))}
      </div>
    </>
  );
};

export default TalentSliderMobile;
